import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Checkbox,
  Input
} from 'semantic-ui-react'

const ModSchedulingDetailUX = (props: any) => {
    return(
      <div
        className="ui form --override-disabled --grey"
        id="ModSchedulingDetailUX-div-0"
        style={{padding: "5px"}}>
        <div
          id="ModSchedulingDetailUX-div-16"
          style={{height: "250px"}}>

          <div
            id="ModSchedulingDetailUX-div-14"
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(18, 1fr)","gridTemplateColumns":"repeat(45, 1fr)"}}>
            <div
              id="ModSchedulingDetailUX-div-17"
              style={{"padding":"5px","textAlign":"right","gridRow":"5/7","gridColumn":"2/11"}}>
              <div
                id="ModSchedulingDetailUX-div-10"
                style={{margin: "0 5px 0 5px"}}>
                เวลาเริ่มออกตรวจ
              </div>
            </div>
            <div
              id="ModSchedulingDetailUX-div-18"
              style={{"gridRow":"5/7","gridColumn":"11/23"}}>
              <Dropdown
                disabled={props.disabled}
                id="ModSchedulingDetailUX-Dropdown-11"
                onChange={props.changeStartSerial}
                options={props.startTimeOptions}
                selection={true}
                value={props.dentistStartSerial}>
              </Dropdown>
            </div>
            <div
              id="ModSchedulingDetailUX-div-19"
              style={{"padding":"5px","textAlign":"right","gridRow":"5/7","gridColumn":"25/33"}}>
              <div
                id="ModSchedulingDetailUX-div-12"
                style={{margin: "0 5px 0 5px"}}>
                เวลาสิ้นสุดออกตรวจ
              </div>
            </div>
            <div
              id="ModSchedulingDetailUX-div-20"
              style={{"gridRow":"5/7","gridColumn":"34/42"}}>
              <Dropdown
                disabled={props.disabled}
                id="ModSchedulingDetailUX-Dropdown-13"
                onChange={props.changeEndSerial}
                options={props.endTimeOptions}
                selection={true}
                value={props.dentistEndSerial}>
              </Dropdown>
            </div>
            <div
              id="ModSchedulingDetailUX-div-21"
              style={{"padding":"5px","gridRow":"8/10","gridColumn":"2/11", display:"flex", justifyContent: "flex-end", alignItems: "center"}}>
              <div
                id="ModSchedulingDetailUX-div-6"
                style={{marginRight: "5px"}}>
                {props.displayZone ? "โซน" : "เลือกเก้าอี้"}
              </div>
            </div>
            <div
              id="ModSchedulingDetailUX-div-22"
              style={{"gridRow":"8/10","gridColumn":"11/23"}}>
              <Dropdown
                disabled={props.disabled}
                id="ModSchedulingDetailUX-Dropdown-5"
                onChange={props.changeChair}
                options={props.chairOptions}
                selection={true}
                style={{display: props.displayZone ? "none" : ""}}
                value={props.chair}>
              </Dropdown>
              <Input
                id="ModSchedulingDetailUX-Input-29"
                readOnly={true}
                style={{display: props.displayZone ? "" : "none"}}
                value={props.zone}>
              </Input>
            </div>
            <div
              id="ModSchedulingDetailUX-div-23"
              style={{"gridRow":"8/10","gridColumn":props.hideOnsiteOnly?"25/33":"23/29"}}>
              <div
                id="ModSchedulingDetailUX-div-15"
                style={{ padding:"5px", display: "flex", alignItems: "center"}}>

                <Checkbox
                  checked={props.inactive}
                  disabled={props.disabled}
                  id="ModSchedulingDetailUX-Checkbox-7"
                  onChange={props.changeInactive}>
                </Checkbox>
                <div
                  id="ModSchedulingDetailUX-div-8"
                  style={{marginLeft: "5px"}}>
                  {props.inactiveLabel || "งดออกตรวจ"}
                </div>
              </div>
            </div>
            <div
              style={{"gridRow":"8/10","gridColumn":"29/42"}}>

              <div
                style={{ padding:"5px", display:  props.hideOnsiteOnly? "none":"flex", alignItems: "center"}}>

                <Checkbox
                  checked={props.isOnsiteOnly}
                  disabled={props.disabled}
                  onChange={props.changeIsOnsiteOnly}>
                </Checkbox>
                <div
                  style={{marginLeft: "5px"}}>
                  ไม่รับนัดผ่าน Mobile app.
                </div>
              </div>
            </div>
            <div
              id="ModSchedulingDetailUX-div-32"
              style={{ gridRow: "8/10", gridColumn: props.hideOnsiteOnly?"34/43":"39/46"}}>
              <div
                id="ModSchedulingDetailUX-div-33"
                style={{ padding:"5px", display:  props.hideExceptNewPatient? "none":"flex", alignItems: "center"}}>

                <Checkbox
                  checked={props.exceptNewPatient}
                  disabled={props.disabled}
                  id="ModSchedulingDetailUX-Checkbox-34"
                  onChange={props.changeExceptNewPatient}>
                </Checkbox>
                <div
                  id="ModSchedulingDetailUX-div-35"
                  style={{marginLeft: "5px"}}>
                  {props.labelExceptNewPatient || "ไม่รับผู้ป่วยใหม่"}
                </div>
              </div>
            </div>
            <div
              id="ModSchedulingDetailUX-div-30"
              style={{"gridRow":"11/13","gridColumn":"25/33",textAlign: "right", marginRight: "5px", padding: "5px"}}>
              ประเภทการออกตรวจ
            </div>
            <div
              id="ModSchedulingDetailUX-div-24"
              style={{"gridRow":"11/13","gridColumn":"34/42"}}>
              <Dropdown
                disabled={props.disabled || props.disabledExaminationType}
                id="ModSchedulingDetailUX-Dropdown-38"
                onChange={props.onChangeExaminationType}
                options={props.examinationTypeOptions}
                selection={true}
                value={props.examinationType}>
              </Dropdown>
            </div>
            <div
              id="ModSchedulingDetailUX-div-25"
              style={{ fontWeight: "bold", fontSize: "1.2rem",  gridRow: "2/4", gridColumn: "2/20", }}>
              {props.blockDetail}
            </div>
            <div
              id="ModSchedulingDetailUX-div-26"
              style={{ textAlign: "right", marginRight: "5px", padding: "5px", gridRow: "11/13", gridColumn: "2/11", display: props.hideSlotLength ? "none" : "" }}>
              เวลาให้บริการต่อคนไข้ (นาที)
            </div>
            <div
              id="ModSchedulingDetailUX-div-27"
              style={{ gridRow: "11/13", gridColumn: "11/20", display: props.hideSlotLength ? "none" : "" }}>
              <Input
                disabled={props.disabled}
                id="ModSchedulingDetailUX-Input-28"
                onChange={props.changeSlotLength}
                value={props.slotLength}>
              </Input>
            </div>
            <div
              id="ModSchedulingDetailUX-div-36"
              style={{ gridRow: "14/16", gridColumn: "34/42" }}>
              <Button
                color="green"
                disabled={props.disabled}
                id="ModSchedulingDetailUX-Button-3"
                onClick={props.finish}
                style={{width: "100%"}}>
                เสร็จสิ้น
              </Button>
            </div>
            <div
              id="ModSchedulingDetailUX-div-37"
              style={{ gridRow: "14/16", gridColumn: "25/33", display: props.allowDeleteSchedule ? "" : "none" }}>
              <Button
                color="red"
                disabled={props.disabledDeleteSchedule}
                id="ModSchedulingDetailUX-Button-31"
                onClick={props.onDeleteSchedule}
                style={{width: "100%", minWidth: "max-content"}}>
                ลบตารางออกตรวจ
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ModSchedulingDetailUX

export const screenPropsDefault = {"blockDetail":"2022-01-01 09:00-15:00"}

/* Date Time : Mon Mar 10 2025 16:25:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form --override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "เสร็จสิ้น"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Button-3"
        },
        "onClick": {
          "type": "code",
          "value": "props.finish"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Dropdown-5"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeChair"
        },
        "options": {
          "type": "code",
          "value": "props.chairOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.displayZone ? \"none\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.chair"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.displayZone ? \"โซน\" : \"เลือกเก้าอี้\""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-6"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.inactive"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Checkbox-7"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeInactive"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inactiveLabel || \"งดออกตรวจ\""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-8"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาเริ่มออกตรวจ"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-10"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Dropdown-11"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeStartSerial"
        },
        "options": {
          "type": "code",
          "value": "props.startTimeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.dentistStartSerial"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาสิ้นสุดออกตรวจ"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Dropdown-13"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEndSerial"
        },
        "options": {
          "type": "code",
          "value": "props.endTimeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.dentistEndSerial"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "label": "divgrid",
      "name": "div",
      "parent": 16,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-14"
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(18, 1fr)\",\"gridTemplateColumns\":\"repeat(45, 1fr)\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-15"
        },
        "style": {
          "type": "code",
          "value": "{ padding:\"5px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-16"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-17"
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"5px\",\"textAlign\":\"right\",\"gridRow\":\"5/7\",\"gridColumn\":\"2/11\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-18"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"5/7\",\"gridColumn\":\"11/23\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-19"
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"5px\",\"textAlign\":\"right\",\"gridRow\":\"5/7\",\"gridColumn\":\"25/33\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-20"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"5/7\",\"gridColumn\":\"34/42\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-21"
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"5px\",\"gridRow\":\"8/10\",\"gridColumn\":\"2/11\", display:\"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-22"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"8/10\",\"gridColumn\":\"11/23\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-23"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"8/10\",\"gridColumn\":props.hideOnsiteOnly?\"25/33\":\"23/29\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-24"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"11/13\",\"gridColumn\":\"34/42\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.blockDetail"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-25"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"1.2rem\",  gridRow: \"2/4\", gridColumn: \"2/20\", }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาให้บริการต่อคนไข้ (นาที)"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-26"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", marginRight: \"5px\", padding: \"5px\", gridRow: \"11/13\", gridColumn: \"2/11\", display: props.hideSlotLength ? \"none\" : \"\" }",
          "valueEN": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-27"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"11/20\", display: props.hideSlotLength ? \"none\" : \"\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 27,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Input-28"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSlotLength"
        },
        "value": {
          "type": "code",
          "value": "props.slotLength"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Input",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Input-29"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.displayZone ? \"\" : \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.zone"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการออกตรวจ"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-30"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"11/13\",\"gridColumn\":\"25/33\",textAlign: \"right\", marginRight: \"5px\", padding: \"5px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "ลบตารางออกตรวจ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDeleteSchedule"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Button-31"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeleteSchedule"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"max-content\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-32"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: props.hideOnsiteOnly?\"34/43\":\"39/46\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-33"
        },
        "style": {
          "type": "code",
          "value": "{ padding:\"5px\", display:  props.hideExceptNewPatient? \"none\":\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.exceptNewPatient"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Checkbox-34"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeExceptNewPatient"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.labelExceptNewPatient || \"ไม่รับผู้ป่วยใหม่\""
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-35"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-36"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"34/42\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 14,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-div-37"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"25/33\", display: props.allowDeleteSchedule ? \"\" : \"none\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || props.disabledExaminationType"
        },
        "id": {
          "type": "value",
          "value": "ModSchedulingDetailUX-Dropdown-38"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeExaminationType"
        },
        "options": {
          "type": "code",
          "value": "props.examinationTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.examinationType"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"8/10\",\"gridColumn\":\"29/42\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding:\"5px\", display:  props.hideOnsiteOnly? \"none\":\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOnsiteOnly"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeIsOnsiteOnly"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่รับนัดผ่าน Mobile app."
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 30,
  "isMounted": false,
  "memo": false,
  "name": "ModSchedulingDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "blockDetail": "2022-01-01 09:00-15:00"
  },
  "width": 55
}

*********************************************************************************** */
