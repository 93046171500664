import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { useIntl } from "react-intl";

import ModEventDetailUX from "./ModEventDetailUX";

// Utils
import { formatADtoBEString } from "react-lib/utils/dateUtils";

// Types
type Provider = {
  dsb: Record<string, any>;
};

type ModEventDetailProps = {
  onEvent: (params: { message: string; params: any }) => void;
  chairList: any[];
  divisionDict: Record<string, { name: string }>;
  isShowChair?: boolean;
  providers: Provider[];
  selectedAppointment?: any;
  selectedBlock: any;
  title: string;
  setEstimatedAt?: (at: string) => void;
  setEstimatedDuration?: Dispatch<SetStateAction<string>>;
  setShowMod?: (show: boolean) => void;
  setTab?: (tab: string) => void;
};

const ModEventDetail = (props: ModEventDetailProps) => {
  const intl = useIntl();
  const handleRowClick = useCallback(
    (rowInfo: any) => {
      // || rowInfo?.original?.doctor_dsb_full
      // * สามารถนัดหมายเกินจำนวน slots ได้
      if (!props.selectedAppointment) {
        return;
      }

      console.log("select provider:", rowInfo?.original);
      props.onEvent({
        message: "AssignAppointmentToBlock",
        params: rowInfo?.original,
      });

      console.log(
        "rowInfo?.original?.doctor_dsb_slot_length:",
        rowInfo?.original?.doctor_dsb_slot_length
      );

      if (props.setShowMod) {
        props.setShowMod(false);
      }

      if (props.setTab) {
        props.setTab("detail");
      }

      if (props.setEstimatedAt) {
        props.setEstimatedAt("");
      }

      if (props.setEstimatedDuration) {
        props.setEstimatedDuration(rowInfo?.original?.doctor_dsb_slot_length);
      }
    },
    [
      props.selectedAppointment,
      props.setEstimatedAt,
      props.setEstimatedDuration,
      props.setShowMod,
      props.setTab,
    ]
  );

  const handleRowProps = useCallback(
    (state: any, rowInfo: any) => ({
      style: {
        backgroundColor:
          props.selectedBlock && rowInfo?.original?.dsb_id === props.selectedBlock?.dsb_id
            ? "#cccccc"
            : "white",
        cursor: "pointer",
      },
      onClick: () => {
        handleRowClick(rowInfo);
      },
    }),
    [handleRowClick, props.selectedBlock]
  );

  const formattedProviders = useMemo(
    () =>
      props.providers
        ?.filter((item) => item.dsb.doctor_dsb_status !== 2)
        ?.filter((item) => item.dsb.doctor_provider_type === "Doctor")
        ?.map((item) => {
          const totalMinutes =
            (Number.parseFloat(item.dsb.doctor_end_time) -
              Number.parseFloat(item.dsb.doctor_start_time)) *
            60;
          const appointments: Record<string, any>[] = item.dsb.appointments || []
          const totalDuration = appointments.reduce<number>(
            (result, item) => result + Number(item.estimated_duration),
            0
          );

          // นับจำนวน appointments ใน dsb
          const numAppointedPatients = appointments.length;
          // estimate_duration(minutes) ใน appointments มารวมกันแล้ว แล้วหาร doctor_dsb_slot_length (minutes) ใน dsb มีเศษปัดขึ้น
          const usedSlots = Math.ceil(totalDuration / item.dsb.doctor_dsb_slot_length);
          const totalSlots = Math.floor(totalMinutes / item.dsb.doctor_dsb_slot_length);

          const baseProvider = {
            ...item.dsb,
            chair: item.dsb.chair_provider_id
              ? props.chairList.find((acc) => acc.provider === item.dsb.chair_provider_id)?.name
              : "ว่าง",
            date: <div style={{ textAlign: "center" }}> {formatADtoBEString(item.dsb.date)} </div>,
            doctor_end_time: <div style={{ textAlign: "center" }}>{item.dsb.doctor_end_time}</div>,
            doctor_provider_type: (
              <div style={{ textAlign: "center" }}> {item.dsb.doctor_provider_type} </div>
            ),
            doctor_start_time: (
              <div style={{ textAlign: "center" }}>{item.dsb.doctor_start_time}</div>
            ),
            doctorName: item.dsb.specialty
              ? `${item.dsb.doctor_provider_name} ${item.dsb.specialty}`
              : `${item.dsb.doctor_provider_name}`,
            dsb: item.dsb,
            examinationTypeLabel:
              item.dsb.doctor_dsb_examination_type === null
                ? "ไม่รับค่าตอบแทนแพทย์"
                : item.dsb.doctor_dsb_examination_type_label,
            numAppointedPatients: <div style={{ textAlign: "center" }}>{numAppointedPatients}</div>,
            number_appointments: (
              <div style={{ textAlign: "center" }}>{`${usedSlots} (${totalSlots})`}</div>
            ),
            zone: (
              <div style={{ textAlign: "center" }}>
                {" "}
                {item.dsb.chair_zone_id
                  ? props.divisionDict[item.dsb.chair_zone_id].name
                  : intl.formatMessage({ id: "ว่าง" })}{" "}
              </div>
            ),
          };

          if (item.dsb.doctor_dsb_full) {
            return {
              ...baseProvider,
              chair: <div style={{ color: "#4F4F4F" }}>{baseProvider.chair}</div>,
              date: <div style={{ color: "#4F4F4F" }}>{formatADtoBEString(item.dsb.date)}</div>,
              doctor_end_time: (
                <div style={{ color: "#4F4F4F", textAlign: "center" }}>
                  {item.dsb.doctor_end_time}
                </div>
              ),
              doctor_start_time: (
                <div style={{ color: "#4F4F4F", textAlign: "center" }}>
                  {item.dsb.doctor_start_time}
                </div>
              ),
              doctorName: (
                <div style={{ color: "#4F4F4F" }}>
                  {item.dsb.specialty
                    ? `${item.dsb.doctor_provider_name} ${item.dsb.specialty}`
                    : `${item.dsb.doctor_provider_name}`}
                </div>
              ),
              examinationTypeLabel: (
                <div style={{ color: "#4F4F4F" }}>{baseProvider.examinationTypeLabel}</div>
              ),
              numAppointedPatients: (
                <div style={{ color: "#4F4F4F", textAlign: "center" }}>{numAppointedPatients}</div>
              ),
              number_appointments: (
                <div style={{ color: "#4F4F4F", textAlign: "center" }}>FULL</div>
              ),
              zone: (
                <div style={{ color: "#4F4F4F", textAlign: "center" }}>{baseProvider.zone}</div>
              ),
            };
          }

          return baseProvider;
        }),
    [props.chairList, props.divisionDict, props.providers]
  );

  return (
    <ModEventDetailUX
      isShowChair={props.isShowChair}
      providers={formattedProviders}
      rowProps={handleRowProps}
      title={props.title}
    />
  );
};

ModEventDetail.displayName = "ModEventDetail";

export default React.memo(ModEventDetail);
